import React from 'react'
import styled from 'styled-components'
// import Img from '.'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

const StyledPartners = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionFlex = styled.div`
  padding: 0 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // max-width: 960px;
  // margin-left: 5rem;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 1rem;
    margin-top: 0rem;
    // flex-direction: ${({ wrapSmall }) => (!wrapSmall ? 'row' : 'column')};
  }
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 560px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: center;
  }
  h1,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  h2 {
    max-width: 650px;
  }
  p {
    /* margin-bottom: 0.5rem; */
    max-width: 650px;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
`

const StyledPartnerCard = styled.div`
  // background-color: ${({ theme }) => theme.cardBG};
  border: 1px solid ${({ theme }) => theme.buttonBorder};
  padding: 1rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.huge};
  min-width: 200px;
  max-width: 200px;
  margin-right: 25px;
  margin-bottom: 12px;
  @media (max-width: 560px) {
    min-width: 160px;
    max-width: 160px;
    margin-right: 15px;
  }
`

const StyledPartnerImage = styled.img`
  width: 100%;
  // max-width: 120px;
  margin-bottom: 1rem;
  background-color: none;
  border-radius: 12px;
`

const Partnership = props => {

  var partners = [
    {
      name: `PancakeSwap`
      , img: `/images/partnership/PancakeSwap.png`
    }
    , {
      name: `Abra`
      , img: `/images/partnership/Abra.png`
    }
    , {
      name: `Accointing.com`
      , img: `/images/partnership/Accointing.png`
    }
    , {
      name: `Audius`
      , img: `/images/partnership/Audius.png`
    }
    , {
      name: `Blockchain Education Network`
      , img: `/images/partnership/Blockchain-Education-Network.png`
    }
    , {
      name: `Brave`
      , img: `/images/partnership/Brave.png`
    }
    , {
      name: `Bybit`
      , img: `/images/partnership/Bybit.png`
    }
    , {
      name: `Cheddar`
      , img: `/images/partnership/Cheddar.png`
    }
    , {
      name: `CoinDesk`
      , img: `/images/partnership/CoinDesk.png`
    }
    , {
      name: `CoinGecko`
      , img: `/images/partnership/CoinGecko.png`
    }
    , {
      name: `Cointelegraph`
      , img: `/images/partnership/Cointelegraph.png`
    }
    , {
      name: `Cointracker`
      , img: `/images/partnership/Cointracker.png`
    }
    , {
      name: `CoinTracking`
      , img: `/images/partnership/CoinTracking.png`
    }
    , {
      name: `Cryptograph`
      , img: `/images/partnership/Cryptograph.png`
    }
    , {
      name: `Eaglebrook Advisors`
      , img: `/images/partnership/Eaglebrook-Advisors.png`
    }
    , {
      name: `Electric Coin Company`
      , img: `/images/partnership/Electric-Coin-Company.png`
    }
    , {
      name: `Elongate`
      , img: `/images/partnership/Elongate.png`
    }
    , {
      name: `Endaoment`
      , img: `/images/partnership/Endaoment.png`
    }
    , {
      name: `Gemini`
      , img: `/images/partnership/Gemini.png`
    }
    , {
      name: `Gitcoin`
      , img: `/images/partnership/Gitcoin.png`
    }
    , {
      name: `GSR`
      , img: `/images/partnership/GSR.png`
    }
    , {
      name: `Inca Digital`
      , img: `/images/partnership/Inca-Digital.png`
    }
    , {
      name: `Munch Project`
      , img: `/images/partnership/Munch-Project.png`
    }
    , {
      name: `R/bitcoin`
      , img: `/images/partnership/R-bitcoin.png`
    }
    , {
      name: `RoundlyX`
      , img: `/images/partnership/RoundlyX.png`
    }
    , {
      name: `Storj`
      , img: `/images/partnership/Storj.png`
    }
    , {
      name: `TaxBit`
      , img: `/images/partnership/TaxBit.png`
    }
    , {
      name: `Zebedee`
      , img: `/images/partnership/Zebedee.png`
    }
    , {
      name: `Zenledger`
      , img: `/images/partnership/Zenledger.png`
    }
  ]
  
  return (
      <Layout path={props.location.pathname}>
        <BG />
  
        <SEO title="Partnership" path={props.location.pathname} />

        <StyledPartners>
          <StyledSectionTitle>
            Partnership
          </StyledSectionTitle>
          <StyledBodyText>
            Rissotto partners span across media, NFT, non-profit, integration and crypto industries.
          </StyledBodyText>
          <StyledSectionFlex id="partnership">
            {partners.map((partner, index) => {
              return (
                <PartnerDetail key={index} {...partner} />
              )
            })}
          </StyledSectionFlex>
        </StyledPartners>
      </Layout>
  )

}

const PartnerDetail = (partner) => {
  return (
    <StyledPartnerCard>
      <div>
        <StyledPartnerImage src={partner.img} />
      </div>
      {partner.name}
    </StyledPartnerCard>
  )
}

export default Partnership